import styled from "styled-components"
import { Navbar,Container } from 'react-bootstrap';
import {Animated} from "react-animated-css";

const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}

const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };

  export  const NavLogo = styled.img`
  padding-left:50px;

      @media ${device.tablet}{
          display:none;   
      }
      @media ${device.laptopL}{
          width: 100%;
          height:auto;
          padding-left:25px;
      }
      
      @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-left:34px;
        height:auto;
        width:100%;    
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        width:100%;
        height:auto;
    }

  `
export const MobLogo = styled.img`
    @media (min-width: 769px){
        display:none;
    }
    
    
`
export const BurgerImg = styled.img`
    padding-right: 80px;
    border:none;
    cursor:pointer;
    
    @media ${device.laptopL}{
        padding-right: 25px;
        width: 85%;

    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
        width:100%;
    }
    @media ${device.tablet}{
        padding-right: 0px;
    }
    
    @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-right:34px;  
    }
    
`

export const NavBar = styled(Navbar)`
    height:auto;
    
    .navbar-toggler{
        border: none;
    }
    .navbar{
        width:100%;
    }
    @media ${device.laptopL} {
        height: 50px;
    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
       height:60px;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
       height:auto;
    }
`
export const Menu = styled(Container)`
    z-index: 9999;
    background-color: black;
    height:100vh;
    width:100%;
    position: fixed;
   
    .bg-dark{
        background-color: black !important;
    }

`
export const MenuImg = styled.img`
    position:absolute;
    left:0;
    bottom:0;
    width:40%;
    height:auto;
    @media ${device.tablet}{
        width:80%;
    }

`
export const MenuLinks = styled.span`
    
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    cursor: pointer;

    color: #FFFFFF;
    @media ${device.laptopL}{
        font-size:24px;
        line-height: 25px;
    }

`
export const MenuBannerContainer = styled(Container)`
    padding-top: 24vh;
    
    .active{
        span
        {
            color:#7A57D1 !important;
        }&:hover{
        text-decoration: none;
        }
    }
    
    .pb-54{
        padding-bottom: 54px;
    }
    @media ${device.laptopL}{
        .pb-54{
            padding-bottom: 24px;
        }
    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
     padding-top: 15vh;
    }
`
export const Animation = styled(Animated)`

    position: fixed;
    z-index: 99999;
    height: 100vh;
    width:100%;
    /* display:none; */
    /* visibility: none; */
    display:none;
`