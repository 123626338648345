import React from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from "styled-components"



const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px',
    
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
    
  };

export const LandingBanner = styled(Container)`
    .banner-img{
        max-width:45%;
        padding-top: 2rem;
    }
    @media (max-width:767px){
        display:none;
        
    }
    @media (max-width: 911px){
        .banner-img{
            padding-top: 8rem;
        }
    }
    padding-top: 26vh;
    @media ${device.laptopL}{
        padding-top: 10vh;
    }
    @media (max-width:1536px) and  (min-height:722px){
        padding-top:22vh;
    }

`
export const LandingBannerMob = styled(Container)`
    padding-top: 10vh;
    @media (min-width:767px){
        display:none;
    }
`
export const BannerCol = styled(Col)`
    padding-top:12rem;
`

export const BannerLeadText = styled.h1`
    /* font-family: 'Montserrat', sans-serif; */
    margin-bottom: 0;
    padding-left: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 103.4%;
    ::-webkit-transform{
        transform:translateZ(0);
    }  
/* or 60px */


    color: #2F353E;
    @media ${device.laptopL}{
        font-size: 37px;
        padding-left:10px;
        /* font-weight:600; */
        line-height: 40px;
    }
    @media ${device.laptop}{
        
        font-size: 40px;
        line-height: 103.4%;
        /* o   r 41px */
        padding-left:0;

        color: #2F353E;
    }
    @media ${device.tablet}{
        font-size:38px;
    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-left:18px;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        padding-left:35px;
        
    }
`

export const BannerImage = styled.img`
    @media ${device.laptopL}{
        width: 50%;
        height: auto;
    }

`
export const BannerSubText = styled.h2`
    /* font-family:'Montserrat', sans-serif; */
    padding-left: 40px;
    padding-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 103.4%;
    margin-bottom:0;
    /* or 33px */


    color: #2F353E;
    @media ${device.laptopL}{
        font-size: 24px;
        padding-left: 10px;
    }
    
    @media ${device.laptop}{
        
        font-size: 15px;
        line-height: 103.4%;
        padding-left:0;
/* or 17px */
    }
    
    @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-left:20px;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        padding-left:35px;
        
    }
`
export const RightWay = styled.span`
    color:#7A57D1;
    font-weight:800;
`

export const ArrowDownContainer = styled(Container)`
    padding-top:4rem;
    cursor:pointer;
    @media ${device.laptopL}{
        padding-top: 8rem;
    }
    @media ${device.laptop}{
        padding-top: 10rem;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        padding-top: 7rem;
    }
    
   
`
export const DownArrowImg = styled.img`
    width: 4%;
    height:auto;
    @media ${device.tablet}{
        width:9%;
        
    }
`