import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'

const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };
  export const OurClientPg = styled(Container)`
    padding-top: 10vh;
  `
  export const OurClientBanner = styled(Container)`
    padding-top: 90px;
    padding-bottom: 90px;
   
    .img-fluid:hover{
        cursor:pointer;
    }
    
  /* .height{
      height:220px;
  } */
  
    /* .cardx{
        height: 85px;
    }*/
    .cardx{
        height:0px !important;
    }
    .cardx .img-top {
        display: none;
        top: 0;
        left: 0;
        z-index: 99;
    }
   .cardx:hover .img-top {
        display: inline;
    }
    .cardx:hover .img-back{
        display:none;
    }
    .olikacardx{
        width:35% !important;
    }

    .zoom {
       
       transition: transform 1s;
       
       /* margin: 0 auto; */
   }
   .cardx{
       margin-left: 50%;
       transform: translateX(-50%);
   }

   .zoom:hover {
       -ms-transform: scale(1.1); /* IE 9 */
       -webkit-transform: scale(1.1); /* Safari 3-8 */
       transform: scale(1.1); 
   }
   #clientsZak{
       margin-top:-30px;
   }
   .secondaryZak{
       margin-top:-70px;
   }
    
    @media ${device.laptopL}{
        padding-top:50px;
        .secondaryAynuk {
        max-width:50%;
        height:auto;
        cursor:pointer;
    }
    .cardx{
        max-width: 55%;
        margin-left: 50%;
        height: 100px;
        transform: translateX(-50%);
    }
    .secondaryExpl{
        max-width:100%;
        height:auto;
        cursor:pointer;
    }
    .secondaryGcp{
        max-width:60%;
        height:auto;
    }
    .secondaryLearningplus{
        max-width: 70%;
        height:auto;
    }
    .secondaryZak{
        max-width:50%;
        height:auto;
      
        cursor:pointer;
    }
    .secondaryOlika{
        max-width:20%;
        height:auto;
        cursor:pointer;
    }
  
        #clientsAynuk{
            width:70%;
            height:auto;
        } 
        #clientsExp{
            width:100%;
            height:auto;
        }
        #clientsGCP{
            width: 80%;
            height:auto;
        }
        #clientsLP{
            width: 90%;
            height:auto;
        }
        #clientsSC{
            width:90%;
            height:auto;
        }
        #clientsZak{
            width:70%;
            height:auto;
            margin-top: -20px;
        }
        #clientsolika{
            width:25%;
            height:auto;
        }
       
    }
    @media (max-width:767px){
        display:none;
    }
  /*  for all laptop */
    #clientsAynuk{
            width:40%;
            height:auto;
        } 
        #clientsExp{
            width:100%;
            height:auto;
        }
        #clientsGCP{
            width: 50%;
            height:auto;
        }
        #clientsLP{
            width: 60%;
            height:auto;
        }
        #clientsSC{
            width:60%;
            height:auto;
        }
        #clientsZak{
            width:50%;
            height:auto;
            margin-top: -20px;
        }
        #clientsolika{
            width:20%;
            height:auto;
        }
    .secondaryAynuk {
        max-width:50%;
        height:auto;
        cursor:pointer;
        }
    .secondaryGcp{
        max-width:55%;
        height:auto;
    }
    .secondaryLearningplus{
        max-width: 70%;
        height:auto;
    }
    .secondaryZak{
        max-width:50%;
        height:auto;
        margin-top: -45px;
        cursor:pointer;
    }
    .secondaryOlika{
        max-width:20%;
        height:auto;
        cursor:pointer;
    }
    
  `
export const OurClientBannerMob = styled(Container)`
    display:none;
    
    @media (max-width: 767px){
        display: inline;
        padding-top:100px;
        overflow-x:hidden;
        /* width:100%; */
    }
`

export const ClientImage = styled(Row)`
    padding-top:215px;
    @media ${device.laptopL}{
        padding-top:150px;
    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-top:180px;
        
    }
  `
export const CstmCarousel = styled(Carousel)`
    overflow-x:hidden;
    height:400px;
    padding-top:22vh;
    padding-bottom:22vh;
    .carousel-indicators{
        bottom:0;
        /* margin-top:50%; */
    }
    .carousel-indicators li {
        background-color:#7A57D1;
        width:15px;
        height:15px;
        border-radius:50%;
        
    }
`

export const ClientMob = styled(Col)`
    /* padding-bottom: 100px; */
    text-align: center;   
`