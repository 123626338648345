import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap';

const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };

export const PageNo = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 300;
    font-size: 60px;
    line-height: 121.3%;
    margin-bottom:0 ;
    /* or 73px */
    padding-left:148px;
    margin-bottom:0;
    color: rgba(122, 87, 209, 0.5);
    @media ${device.laptopL}{
        padding-left:74px;
        font-size: 40px;
    }
    @media ${device.tablet}{
        padding-left: 19px;

    }

`
export const PageSubText = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 121.3%;
    padding-left: 150px;
    margin-bottom:0;
/* or 36px */


    color: #2F353E;
    @media ${device.laptopL}{
        padding-left: 74px;
        font-size: 20px;
    }
    @media ${device.tablet}{
        padding-left:19px;
    }
`
export const OurWorkPg = styled(Container)`

    padding-top:15vh;

`
export const OurWorkBanner = styled(Container)`

    /* padding-top:60px; */
    /* padding-bottom: 30px; */
    /*overflow-x:hidden;*/
    .fadeInDown {
        animation: fade-in-down 1s;
    }
    .faderight{
        animation: fade-in-right 1s;
    }
    @keyframes fade-in-down {
        0% {
            opacity: 0;
            transform: translateY(-40px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @keyframes fade-in-right{
        0% {
            opacity: 0;
            transform: translateX(40px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    @media ${device.tablet}{
        padding-top:28px;
    }
`
export const OurWorkId = styled.p`

    /* font-family: 'Montserrat', sans-serif; */
    font-size: 48px;
    line-height: 71px;
    font-weight: 800;
    padding-left: 70px;
    margin-bottom:0;
   
    /* color: #D1111C; */
    @media ${device.laptopL}{
        padding-left:64px;
        font-size: 38px;
    }
    @media ${device.tablet}{
        padding-left:0;
        font-style: normal;
        
        font-size: 40px;
        line-height: 49px;

        
    }
`
export const OurWorkContent = styled(Col)`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    text-align:left;
    font-size: 16px;
    line-height: 20px;
    letter-spacing:0.2px;
    padding-left:85px;
    padding-top:40px;
    color: #2F353E;
    @media ${device.laptopL}{
        padding-left:74px;
        font-size: 16px;
        line-height: 20px;
        padding-top:0;
    }
    @media ${device.tablet}{
        padding-left: 12px;
        font-size: 16px;
        line-height: 103.4%;
/* or 17px */



    }
`
export const OurWorkIdCol = styled(Col)`
    #idem{
        color:#D1111C;
        padding-top: 80px;
        padding-right:150px;
        
    }
    #learning{
        color:#2182BF;
        padding-right:150px;
        padding-top: 80px;
    }
    #machina{
        color:#F04037;
        padding-top: 280px;
    }
    #drift{
        color:#169E94;
        padding-top: 80px;
    }
    #aynuk{
        color:#FF0000;
        padding-right: 150px;
        padding-top: 280px;
    }
    @media ${device.laptopL}{
        #idem,#learning,#machina,#aynuk,#drift{
            padding-top:40px;

        }

    }
    @media ${device.tablet}{
        #idem,#learning,#machina,#aynuk,#drift{
            padding-top:0;
        }
    }

`
export const ProductImage = styled(Col)`
    text-align:right;

    .learningClass,.aynukClass{
        padding-right:150px;
        width:65%;

    }
    .idemClass,.machinaClass,.driftClass{
        padding-right:75px;
        max-width:80%;
    }
    @media ${device.laptopL}{
        text-align:center;
        .idemClass{
            width:80%;
            height:auto;
            padding-right:0px;
            padding-left:150px;
        }
        .machinaClass{
            width:75%;
            height:auto;
            padding-right:0px;
            padding-left:150px;
        }
        .driftClass{
            width:80%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:150px;
            padding-top: 0;
        }
        .learningClass,.aynukClass{
            width:60%;
            height:auto;
            padding-right:0px;
            padding-left:150px;
        }
    }
    @media ${device.laptop}{
        .idemClass{
            width:100%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:19px;
            padding-top:50px;
        }
        .machinaClass{
            width:100%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:19px;
            padding-top:50px;
        }
        .driftClass{
            width:100%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:19px;
            padding-top: 0;
        }
        .learningClass,.aynukClass{
            width:70%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:85px;
            padding-top:50px;
        }
    }

    @media ${device.tablet}{
        .idemClass{
            width:100%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:19px;
            padding-top: 0;
        }
        .machinaClass{
            width:100%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:19px;
            padding-top: 0;
        }
        .driftClass{
            width:100%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:19px;
            padding-top: 0;
        }
        .learningClass,.aynukClass{
            width:70%;
            height:auto;
            text-align:center;
            padding-right:0;
            padding-left:85px;
            padding-top: 0;
        }
    }
   
`
export const ArrLeft = styled.img`
    padding-right:60px;
    /* padding-bottom:190px;
    padding-top:75px; */
    cursor: pointer;
    @media ${device.laptopL}{
        width:4.5%;
        height:auto;
        padding-right:40px;
        padding-bottom: 45px;
        padding-top:60px;
    }
    
    @media ${device.tablet}{
        width:10%;
        height:auto;
    }
    @media ${device.mobileL}{
        width:18%;
        height:auto;
    }

`
export const ArrRight = styled.img`
    padding-left: 60px;
    /* padding-bottom: 90px;
    padding-top:75px; */
    cursor:pointer;
    @media ${device.laptopL}{
        width:4.5%;
        height:auto;
        padding-left:40px;
        padding-bottom: 45px;
        padding-top:60px;
    }
    @media ${device.tablet}{
        width:10%;
        height:auto;
    }
    @media ${device.mobileL}{
        
        width:18%;
        height:auto;
    }
`
export const LeadRow = styled(Row)`
    height: 450px;
    @media ${device.laptopL}{
        height: 291px;

    }
    @media ${device.laptop}{
        height:500px;
    }
    @media ${device.tablet}{
        height: auto;
    }
    @media ${device.mobileL}{
        height: 500px;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        height:650px;
    }
`

export const ArrowRow = styled(Row)`
    
    
    /* @media ${device.tablet}{
        margin-top:25vh;
    } */
    
`