import React, {useState} from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { OurWorkPg,OurWorkBanner, OurWorkId, ArrLeft, ArrRight, OurWorkIdCol, ProductImage, OurWorkContent,  LeadRow, ArrowRow } from './OurWork-style';
import { PageNo, PageSubText } from '../HowWeWork/HowWeWork-style';
import ArrowLeft from '../../images/OurWork/Arrow 4.jpg';
import ArrowRight from '../../images/OurWork/Arrow 5.jpg';
import DriftImg from '../../images/OurWork/drift.png';
import AynukImg from '../../images/OurWork/aynuk.png';
import IdemImg from '../../images/OurWork/idem.png';
import LearningImg from '../../images/OurWork/learning.png';

function OurWork() {
    const [productPage, setPage] = useState(0);
   
    const Products = [
        {
            id:'idem',
            name: 'IDEM',
            src:`${IdemImg}`,
            class: 'idemClass',
            content: 'A smart, automated and contactless AI Based attendance system; IDEM uses CCTV camera footage to detect and recognize an individual as part of authentication. Once authenticated, the individual’s attendance is marked and they are notified.'
            
        
        },
        {
            id:'learning',
            name: 'Learning Plus',
            src:`${LearningImg}`,
            class:'learningClass',
            content: 'Learning Plus is an O/A Level Past Paper Resource application. The application allows users to view past papers and video lectures of their choice; hence helping them to study effectively. The app aids students in exam preparation, using the in-app pdf reader and video player. '
        },
        // {
        //     id:'machina',
        //     name: 'Machina Tires',
        //     src:'/static/media/machina.2ba1e578.png',
        //     class:'machinaClass',
        //     content: 'Machina Tires is fully mobile tire change provider in the GTA and Vaughan. Booking with a technician is required for a fast tire change experience. ByteOps collaborated with Seedcode in the development of this Machina Tire’s website'
        // },
 //       {
 //           id:'aynuk',
 //           name:'Aynuk',
 //           src:`${AynukImg}`,
 //           class:'aynukClass',
 //           content:'A mobile application, augmented with Artificial Intelligence, according to varying needs of different customers with no two customers belonging to different segments experiencing the same thing, hence providing a unique User Experience.'
 //       },
        {
            id:'drift',
            name: 'D.R.I.F.T',
            src:`${DriftImg}`,
            class:'driftClass',
            content:'An in-house product, proudly under development in collaboration with mentors from the valley. They see its potential as innovative and disruptive for mainstream verticals.'
        }

    ]
    const onNextClick = () => {
        productPage === (Products.length-1) ?
        setPage(0):
        setPage(productPage + 1);
        setfadeInDown(true);
        setfaderight(true);
    }
    const onPrevClick = () =>{
        productPage === 0 ?
        setPage(Products.length - 1):
        setPage(productPage - 1)
        setfadeInDown(true);
        setfaderight(true);
    }
    
      const [fadeInDown, setfadeInDown] = useState(false);
      const [faderight, setfaderight] = useState(false);
    return (
        <>
           <OurWorkPg fluid id="page-4">
                <Row>
                    <Col>
                        <PageNo >03</PageNo>
                        <PageSubText >Our Work</PageSubText>
                    </Col>    
                </Row>   
            </OurWorkPg>

            <OurWorkBanner fluid>
                            
                <LeadRow >
                    <Col md={6} className={faderight ? 'faderight' : ''} onAnimationEnd={()=>{setfaderight(false)}} >
                        <Container>
                            <Row>
                                <OurWorkIdCol lg={12}>
                                    <OurWorkId id={Products[productPage].id} >{Products[productPage].name}</OurWorkId>
                                </OurWorkIdCol>
                                <OurWorkContent lg={12}>
                                    <p >{Products[productPage].content}</p>
                                </OurWorkContent>
                            </Row>
                        </Container>
                    </Col>
                    <ProductImage md={6} className={fadeInDown ? 'fadeInDown' : ''} onAnimationEnd={()=>{setfadeInDown(false)}}>
                     

                        <img key={Products[productPage].id} src={Products[productPage].src} className={Products[productPage].class} alt="" />
                     
                    </ProductImage>
                </LeadRow>
                <ArrowRow className="justify-content-center">
                    <Col md={12} className="text-center">
                        <ArrLeft src={ArrowLeft} className="" onClick={onPrevClick} alt=""/>
                        <ArrRight src={ArrowRight} className="" onClick={onNextClick} alt=""/>
                    </Col>
                    
                </ArrowRow>
            </OurWorkBanner>
            {/* <NavArrows class="pt-5" > */}
              
            {/* </NavArrows> */}
         
        </>
    )
}

export default OurWork


