import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap';

const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };

export const PageNo = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 300;
    font-size: 55px;
    line-height: 121.3%;
    margin-bottom:0;
    /* or 73px */
    padding-left:80px;
    margin-bottom:0;
    color: rgba(122, 87, 209, 0.5);
    @media ${device.laptopL}{
        padding-left:74px;
        font-size: 40px;
    }
    @media ${device.tablet}{
        padding-left: 19px;

    }


`

export const PageSubText = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 121.3%;
    padding-left: 83px;
/* or 36px */


color: #2F353E;
    @media ${device.laptopL}{
        padding-left: 74px;
        font-size: 20px;
    }
    @media ${device.tablet}{
        padding-left:19px;
    }
`
export const HowWeWorkBanner = styled(Container)`

    padding-top:15vh;
    @media ${device.laptopL}{
        padding-top:0vh;
    }
    
`
export const HowWeWorkLead = styled.h1`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 71px;
    padding-left:66px;
    color: #2F353E;
    @media ${device.laptopL}{
        padding-left:64px;
        font-size: 38px;
    }
    @media ${device.tablet}{
        padding-left:0;
        font-style: normal;

        font-size: 40px;
        line-height: 49px;

        
    }
    

`
export const Results = styled.span`
    color:#7A57D1;
    font-weight: 800;
`
export const HowWeWorkContentContainer = styled(Container)`
    padding-top:24px;
    @media ${device.laptopL}{
        padding-top:0px;
    }
`

export const HowWeWorkContent = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom:0;
    text-align: left;
    padding-left:70px;
    color: #2F353E;
    @media ${device.laptopL}{
        padding-left:64px;
        font-size: 16px;
        line-height: 20px;
    }
    @media ${device.tablet}{
        padding-left: 0px;
        font-size: 16px;
        line-height: 103.4%;
/* or 17px */



    }
`

export const RoadMap = styled(Col)`
    /*padding-top: 88px;*/
    /* padding-left: 60px; */
    text-align: center;
    padding-bottom: 90px;
    
    @media ${device.laptopL}{
        padding-top: 0px;
    }
    @media ${device.tablet}{
        /*padding-top: 50px;*/
        padding-top: 20px
        padding-bottom: 0px;
    }
    @media ${device.tablet}{
        padding-bottom: 0px;
    }
`
export const RoadMapImg = styled.img`
    max-width:100%;
    height:auto;
    @media (max-width: 767px){
        display: none;
    }
    
`
export const RoadMapImgMob = styled(Container)`
    display: none;
    @media (max-width: 767px){
        display:inline;
        display: flex;    
        overflow-x: auto;
        
    }
`
export const RoadMapImgMobImg = styled.img`
    padding-top:2px;
     
    @media (max-width: 767px){
        display:inline;
           
    }
`

export const RoadBanner = styled(Row)`
    padding-top:2.3rem;
    padding-right:4.4rem;
    padding-left:4.4rem;
    .road-map-anim{
        width:20%;
    }
    @media ${device.laptopL}{
        padding-left: 4rem;
        padding-right: 4rem;
    }
    @media ${device.laptop}{
        padding-left:0;
        padding-right: 0;
    }
`