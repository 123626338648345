import React, {useState} from 'react'
import { Button,Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BtnArrow, ContactUsPg,PoweredBy, CstmForm, ForQuestions, LogoRow, LogoRowMob, PhoneNumber, Powered, PoweredContainer } from '../ContactUs/ContactUs-style';
import { PageNo, PageSubText } from '../HowWeWork/HowWeWork-style';
import BannerImg from '../../images/ContactUs/Group 22.png'
import { BannerImage, ContactUsBanner } from '../ContactUs/ContactUs-style';
import Arrow from '../../images/ContactUs/Arrow 6.svg'
import Phone from '../../images/ContactUs/Call.svg'
import Mail from '../../images/ContactUs/email.svg'
import PakFlag from '../../images/ContactUs/Pakistan.jpg'
import CanadaFlag from '../../images/ContactUs/Canada.jpg'
import UKFlag from '../../images/ContactUs/UK.jpg'
import USAFlag from '../../images/ContactUs/USA.jpg'
import PakFlagActive from '../../images/ContactUs/pakActive.png'
import CanadaFlagActive from '../../images/ContactUs/canadaActive.png'
import UKFlagActive from '../../images/ContactUs/UKActive.png'
import USAFlagActive from '../../images/ContactUs/USAActive.png'
import location from '../../images/ContactUs/Pin.svg'
import poweredLogo from '../../images/ContactUs/ByteCorp.svg'
import twitter from '../../images/ContactUs/twitter.svg'
import facebook from '../../images/ContactUs/facebook.svg'
import instagram from '../../images/ContactUs/instagram.svg'
import linkedin from '../../images/ContactUs/linkedin.svg'
import emailjs from 'emailjs-com';

function ContactUs() {
    const Contact = [
        {
            
            phone: '+514 652 3970',
            mail:'info@bytecorp.io',
            address: '295 Chapel Street East, Ottawa.'
        },
        {
            phone: '+923 12345-BYTE (2983)',
            mail:'info@bytecorp.io',
            address: '2nd Floor, A-352, Block-15 Gulistan-e-Jauhar, Karachi, Pakistan'
            
        },
        {
            phone: 'xxxx xxx xxxx',
            mail:'info@bytecorp.io',
            address: 'ByteCorp 307, Walsall Road, Birmingham, United Kingdom , B42 1UH.'
            
        },
        {
            phone: 'xxx-xxxxx-xxxx',
            mail:'info@bytecorp.io',
            address: '16526 W 78th Street # 220 Eden Prairie, MN 55346.'
            
        }


    ]
    const [contact, setContact] = useState(0);
    const toggleAddress = (prop) => {
        let str = prop.target.id;
        if(str === 'contact0'){ 
            document.getElementById(str).src = `${CanadaFlagActive}`;

        }else{
            document.getElementById("contact0").src =  `${CanadaFlag}`;
        }
        if(str === 'contact1'){
            document.getElementById(str).src = `${PakFlagActive}`;
        }else{
            document.getElementById('contact1').src = `${PakFlag}`;
        }
        if(str === 'contact2'){
            document.getElementById(str).src = `${UKFlagActive}`;
        }else{
            document.getElementById('contact2').src = `${UKFlag}`;
        }
        // if(str === 'contact3'){
        //     document.getElementById(str).src = `${USAFlagActive}`;
        // }else{
        //     document.getElementById('contact3').src = `${USAFlag}`;
        // }
        
        str = str.slice(str.length - 1, str.length);

        setContact(str);
    } 
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_ft16w8v', 'template_jn0ykyv', e.target, 'user_aWvi6of70dM18CrKlLrJe')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset();
      }
    const Alert = () =>{
        alert("Your message has been sent!");
    }  
    
    return (
        <>
            <ContactUsPg id="page-7" fluid>
                <Row>
                    <Col>
                        <PageNo >05</PageNo>
                        <PageSubText >Contact Us</PageSubText>
                    </Col>
                </Row>
            </ContactUsPg>
            <ContactUsBanner fluid>
                <Row >
                    <Col xs={12} md={8}>
                    <CstmForm onSubmit={sendEmail}>
                        <CstmForm.Row>
                            <CstmForm.Group as={Col} xs={12} md={6} controlId="formGridName">
                                <CstmForm.Label >Your Name</CstmForm.Label>
                                <CstmForm.Control  type="text" name="name" placeholder="Enter Your Name" required/>
                            </CstmForm.Group>

                            <CstmForm.Group as={Col} xs={12} md={6} controlId="formGridEmail" className="pt-35">
                                <CstmForm.Label >Email Address</CstmForm.Label>
                                <CstmForm.Control  type="email" name="email" placeholder="Enter Your Email" required/>
                            </CstmForm.Group>
                        </CstmForm.Row>
                        <CstmForm.Row className="pt-80">
                            <CstmForm.Group as={Col} xs={12}  controlId="formGridTextarea">
                                <CstmForm.Label >Message</CstmForm.Label>
                                <CstmForm.Control  as="textarea" name="message" rows="5"  placeholder="Enter Your Message Here" required/>
                            </CstmForm.Group>
                            
                        </CstmForm.Row>
                        <CstmForm.Row className="pt-50 justify">
                            <Button type="submit"  onClick={Alert}>
                                Submit <BtnArrow src={Arrow} alt=""/>
                            </Button>
                        </CstmForm.Row>
                        <CstmForm.Row className="pt-50 dsp">
                            <Col>
                                <ForQuestions >For any questions, contact us on:</ForQuestions>
                                <PhoneNumber > <img src={Phone} alt="" className="pb-0" /><span className="pt-1">{Contact[contact].phone}</span></PhoneNumber>
                                <PhoneNumber> <img src={Mail} alt="" className="pb-0" /><span className="mt-2 pl-2">{Contact[contact].mail}</span> </PhoneNumber>
                            </Col>
                           
                          
                        </CstmForm.Row>
                        <CstmForm.Row className="pt-60  mobile-switch">
                            <Row>
                                <Col className="text-center">
                                    <ForQuestions  >For any questions, contact us on:</ForQuestions>
                                </Col>
                              </Row>
                            <Row className="pt-4">
                                <Col className="text-center">
                                    <PhoneNumber > <img src={Phone} alt="" className="pb-0" /><span className="pt-1">{Contact[contact].phone}</span></PhoneNumber>
                                    <PhoneNumber > <img src={Mail} alt="" className="pb-0" /><span className="mt-2 pl-2">{Contact[contact].mail}</span> </PhoneNumber>
                                </Col>
                            </Row>
                            
                            
                        </CstmForm.Row>
                        <CstmForm.Row className="pt-30 justify">
                            <img src={UKFlag} id="contact2" onClick={toggleAddress} className="pl-1 zoom" alt=""/>
                            {/* <img src={USAFlag} id="contact3" onClick={toggleAddress} className="pl-1 zoom" alt=""/> */}
                            <img src={CanadaFlag} id="contact0" onClick={toggleAddress} className="pl-1 zoom" alt="" />
                            <img src={PakFlag} id="contact1" onClick={toggleAddress} className="pl-1 zoom" alt=""/>
                            

                        </CstmForm.Row>
                        <CstmForm.Row className="pt-25 dsp">
                            <Row>
                                <Col>
                                    <ForQuestions ><img src={location} alt=""/><span className="pl-2">{Contact[contact].address}</span></ForQuestions>    
                                </Col>
                               
                            </Row>

                            
                        </CstmForm.Row>
                        <CstmForm.Row className="pt-5 mobile-switch">
                            <Row>
                                <Col className="text-center">
                                    <img src={location} alt=""/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <ForQuestions >{Contact[contact].address}</ForQuestions>     
                                </Col>
                            </Row>
                           
                        </CstmForm.Row>
                    </CstmForm>
                    </Col>
                    <Col className="align-self-end">
                        {/* <Row >
                            <Col >
                                <BannerImage src={BannerImg} className="img-fluid ml-auto mr-0" alt=""/>    
                            </Col>
                        </Row> */}
                        <Row>

                            <Col>
                                <Row>
                                    <PoweredContainer>
                                        
                                        <a href="https://bytecorp.io" rel="noopener noreferrer" target='_blank'>
                                            <Powered src={poweredLogo} alt="" className="img-fluid"/>
                                        </a>
                                    </PoweredContainer>
                                </Row>
                                <LogoRow className="justify-content-end">
                                    <Col xs={1} md={1} className="pr-2-2">
                                        <a target='_blank' href="https://twitter.com/ByteCorp"  rel="noopener noreferrer">
                                            <img src={twitter} className="zoom" alt=""/>
                                        </a>

                                    </Col>
                                    <Col xs={1} md={1} className="pr-2-2">
                                        <a target='_blank' href="https://www.facebook.com/bytecorp.io"  rel="noopener noreferrer"><img src={facebook} className="zoom" alt="" /></a>
                                    </Col>
                                    <Col xs={1} md={1} className="pr-2-2">
                                        <a target='_blank' href="https://www.instagram.com/bytecorp_io/"  rel="noopener noreferrer"><img src={instagram} className="zoom" alt=""/></a>
                                    </Col>
                                    <Col xs={1} md={1} className="pr-2-2">
                                        <a target='_blank' href="https://www.linkedin.com/company/20346142/admin/"  rel="noopener noreferrer"><img src={linkedin} className="zoom" alt=""/></a>
                                    </Col>
                                </LogoRow>
                                <LogoRowMob className="justify-content-center">
                                    <Col xs={1} className="pr-2-2">
                                        
                                        <a target='_blank' href="https://twitter.com/ByteCorp"  rel="noopener noreferrer" ><img src={twitter} alt=""/></a>

                                    </Col>
                                    <Col xs={1} className="pr-2-2">
                                        <a target='_blank' href="https://www.facebook.com/bytecorp.io"  rel="noopener noreferrer"><img src={facebook} alt=""/></a>
                                    </Col>
                                    <Col xs={1} className="pr-2-2">
                                        <a target='_blank' href="https://www.instagram.com/bytecorp_io/"  rel="noopener noreferrer"><img src={instagram} alt=""/></a>
                                    </Col>
                                    <Col xs={1} className="pr-2-2">
                                        <a target='_blank' href="https://www.linkedin.com/company/20346142/admin/"  rel="noopener noreferrer"><img src={linkedin} alt=""/></a>
                                    </Col>
                                </LogoRowMob>
                            </Col>
                        </Row>
                        
                    </Col>
                    
                </Row>
            </ContactUsBanner>
        </>
    )
}

export default ContactUs
