import React from 'react'
import { Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import styled from 'styled-components';
import { RoadMapImgMobImg ,PageNo,PageSubText, HowWeWorkBanner, HowWeWorkLead, Results, HowWeWorkContentContainer, HowWeWorkContent, RoadMap, RoadMapImg, RoadMapImgMob,RoadBanner } from './HowWeWork-style';
import HowWeWorkRoadMob from '../../images/HowWeWork/CardMobile.svg';
import Ideation from '../../images/HowWeWork/Ideation.png'
import Roadmap from "../../images/HowWeWork/Roadmap.png"
import Wireframes from '../../images/HowWeWork/Wireframes.png'
import Prototype from '../../images/HowWeWork/Prototype.png'
import MVP from '../../images/HowWeWork/MVP.png'
import ScrollAnimation from 'react-animate-on-scroll';



function HowWeWork() {

    // let topScroll = document.getElementById('page-1').scrollTop;
    // console.log(topScroll);
    return (

        <>
            <HowWeWorkBanner fluid id="page-3">
                <Row>
                    <Col >
                    <PageNo>02</PageNo>
                    <PageSubText>How We Work</PageSubText>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <HowWeWorkContentContainer fluid>
                            <Row>
                                <Col>
                                    <HowWeWorkLead>
                                        Design Thinking for <Results>Better Results</Results>
                                    </HowWeWorkLead>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={7}>
                                    
                                    <HowWeWorkContent>
                                        Here at ByteXP, we empathize better. We carefully understand and execute each 
                                        phase with precision, discipline and effective measures. When we take up a challenge, 
                                        we own up to it and we deliver perfection. Our history of tackling challenging Design and 
                                        Development Products separates us from the norm.
                                    </HowWeWorkContent>
                                </Col>
                            </Row>
                            <Row>
                                <RoadMap className="text-center" md={12}>
                                    <RoadBanner>
                                        {/* <FadeIn className="row" delay="300" childClassName="road-map-anim"> */}
                                           
                                            <Col>
                                                <ScrollAnimation animateIn="fadeInDown" animateOnce="1" duration="2">
                                                    <RoadMapImg src={Ideation} alt="roadmap"/>
                                                </ScrollAnimation>
                                            
                                            </Col>
                                            <Col>
                                                <ScrollAnimation animateIn="fadeInDown" animateOnce="1" duration="3">
                                                    <RoadMapImg src={Roadmap} alt=""/>
                                                </ScrollAnimation>
                                            </Col>
                                            <Col>
                                                <ScrollAnimation animateIn="fadeInDown" animateOnce="1" duration="4">
                                                    <RoadMapImg src={Wireframes} alt=""/>
                                                </ScrollAnimation>    
                                            </Col>
                                            <Col>
                                                <ScrollAnimation animateIn="fadeInDown" animateOnce="1" duration="5">
                                                    <RoadMapImg src={Prototype} alt="" />
                                                </ScrollAnimation>
                                            </Col>
                                            <Col>
                                                <ScrollAnimation animateIn="fadeInDown" animateOnce="1" duration="6">
                                                    <RoadMapImg src={MVP} alt=""/>
                                                </ScrollAnimation>
                                            </Col>
                                           
                                        {/* </FadeIn> */}
                                    </RoadBanner>
                                    
                                    <RoadMapImgMob>
                                    
                                    <RoadMapImgMobImg src={HowWeWorkRoadMob} alt="" />
                                    
                                        {/* <Row className="justify-content-center">
                                            <RoadMapImgMobImg src={Ideation} alt="" />
                                        </Row>
                                        <Row className="justify-content-center">
                                            <RoadMapImgMobImg src={Roadmap} alt=""/>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <RoadMapImgMobImg src={Wireframes} alt=""/>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <RoadMapImgMobImg src={Prototype} alt=""/>
                                        </Row>
                                        <Row className="justify-content-center">
                                            <RoadMapImgMobImg src={MVP} alt=""/>
                                        </Row>
                                         */}
                                    </RoadMapImgMob>
                                </RoadMap>
                            </Row>
                        </HowWeWorkContentContainer>
                    </Col>
                </Row>

            </HowWeWorkBanner>
        </>
    )
}

export default HowWeWork
