import Landing from './Components/Landing/Landing'
import Navbar from './Components/Navbar/Navbar'
import Whyops from './Components/Whyops/Whyops'
import HowWeWork from './Components/HowWeWork/HowWeWork'
import OurWork from './Components/OurWork/OurWork'
import OurClient from './Components/OurClient/OurClient'
import CaseStudies from './Components/CaseStudies/CaseStudies'
import ContactUs from './Components/ContactUs/ContactUs'
import { createGlobalStyle } from 'styled-components';
import CookieConsent from "react-cookie-consent";
import './App.css';
// import font from './fonts/Mon';

 

function App() {
  
  return (
    <>
    
      
      <Navbar/>
      <Landing/>
      <Whyops/>
      <HowWeWork/>
      <OurWork/>
      <OurClient/>
      {/* <CaseStudies/> */}
      <ContactUs/>
      <CookieConsent>
          This website uses cookies to enhance the user experience.
      </CookieConsent>
      
    </>
    
  );
}

export default App;
