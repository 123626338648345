import styled from "styled-components"
import { Container,Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import BannerImg from '../../images/ContactUs/Group 22.png'

const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };

export const ContactUsPg = styled(Container)`
    padding-top: 10vh;
    
    @media (max-width: 1920px) and (min-height: 969px){
    margin-top:8vh;
    
}
`
export const BannerImage = styled.img`
    
    width: 100%;
    
    @media (max-width: 767px){
        display:none;

    }
`
export const ContactUsBanner = styled(Container)`
  
    padding-top: 49px;
    padding-bottom: 2vh;
    margin-bottom:100px;
    background: url("${BannerImg}");
    background-position: right;
    
    ${'' /* background-size: inherit; */}
    background-size:40% 80%;
    background-repeat: no-repeat;
    height: 500px;

    @media (max-width: 769px){
       background-image: none;
    }

    @media ${device.laptopL}{
        padding-top:30px;

    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-top:20px;
        
    }
`
export const BtnArrow = styled.img`
    padding-bottom:1px;
    @media ${device.tablet}{
        width:20%;
        height:auto;
    }
`
export const CstmForm = styled(Form)`
    padding-left: 150px;
    
    @media (min-width: 1536px) and (max-width: 1536px)  {
        padding-left:85px;
        
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        padding-left:80px;
        
    }

    .mobile-switch{
        display:none;
    }
    
    #contact0{
        cursor:pointer;
    }
    #contact1{
        cursor:pointer;
    }
    #contact2{
        cursor:pointer;
    }
    #contact3{
        cursor:pointer;
    }
    .zoom {
       
        transition: transform 1s;
        
        /* margin: 0 auto; */
    }

    .zoom:hover {
        -ms-transform: scale(1.2); /* IE 9 */
        -webkit-transform: scale(1.2); /* Safari 3-8 */
        transform: scale(1.2); 
    }
    .form-label{
        /* font-family: "Montserrat", sans-serif; */
        font-size: 16px;
        line-height: 23px;
        font-weight:bold;
        color: #7A57D1;
    }
    .pt-30{
        padding-top:30px;
    }
    .pt-50{
        padding-top:50px;
    }
    .pt-60{
        padding-top:60px;
    }
    .pb-0{
        padding-bottom: 0.3rem;
    }
    .pt-25{
        padding-top:25px;
    }
    .pt-80{
        padding-top:20px;
    }
    
    .form-control{
        /* font-family: "Montserrat", sans-serif; */
        width: 70%;
        border: none;
        border-radius: 0;
        font-size:0.75rem;
        border-bottom: 1px solid #9C9C9C;
        padding:0;
        &:focus{
            /* border:none; */
            box-shadow:none;
        }
        
    }
    
    
    textarea{
        width:85% !important;
        resize:none;
    }
    .btn-primary{
        height:40px;
        width:140px;
        font-style: normal;
        font-weight: 500;
        /* font-family: "Montserrat",sans-serif; */
        font-size: 14px;
        line-height: 19px;
        background: #7A57D1;
        box-shadow: 0px 4px 16px rgba(122, 87, 209, 0.8);
        border-radius: 49px;
        color: #FFFFFF;
        border:none;
    }
    
    @media ${device.laptopL}{
        padding-left: 74px;
        .btn-primary{
            height:40px;
            width:167px;
        }
       .pt-80{
           padding-top:50px;
       }
    }
    @media ${device.laptop}{
        padding-left:19px;
    }
    @media (max-width: 767px){
        padding-left:19px;
        padding-right:19px;
        
        .form-control{
            width:100%;
        }
        .pt-35{
            padding-top:35px;
        }
        .btn-primary{
            width:92px;
            height:26px;
            font-size: 10px;
            line-height: 12px;
        }
        textarea{
            width:100% !important;
            
        }
        .justify{
            justify-content:center !important;
        }
        .dsp{
            display:none;
        }
        .mobile-switch{
            display:block;
        }
    }
`
export const ForQuestions = styled.span`

    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #9C9C9C;
    @media ${device.tablet}{
        font-size: 10px;
        line-height: 12px;


    }
`
// export const PoweredBy = styled.h1`
//     font-style: normal;
//     font-weight: 600;
//     font-size: 20px;
//     color: #7A57D1;

//     @media ${device.laptopL}{
//         padding-right:130px;
//         padding-top:68px;

//     }
//     @media ${device.desktop}{
//         padding-right:130px;
        
//     }
//     @media ${device.mobileL}{

//         padding-right:0px;
//     }
    
// @media (min-width: 1536px) and (max-width: 1536px){
//         padding-right:130px;
//         margin-top:-40px;
        
//     }
    
// @media (max-width: 1366px) and (max-height: 578px){
//     padding-right:185px;
//     padding-top:50px;
// }
// @media (max-width: 1920px) and (min-height: 969px){
//     padding-right:270px;
//     margin-top:-100px;   
// }
// `
export const PhoneNumber = styled.span`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding-left: 11px;
    color: #7A57D1;
    @media ${device.tablet}{
        font-size:10px;
        line-height:12px;
    }
`
export const Powered = styled.img`
    padding-right:35px;
    ${'' /* @media ${device.laptopL}{
        padding-right:80px;
    } */}
    @media ${device.tablet}{
        
        padding-right:0;
    }
    
${'' /* @media (min-width: 1536px) and (max-width: 1536px){
        padding-right:70px;
        
}
@media (min-width: 1920px) and (max-width: 1920px){
        padding-right:90px;
        
} */}
    
    ${'' /* @media (max-width: 1366px) and (min-height: 578px){
    padding-right:168px;
    padding-top:0px;
}
@media (max-width: 1920px) and (min-height: 969px){
    padding-right:250px;
    padding-top:5px;
} */}

`
export const PoweredContainer = styled(Col)`
    text-align: right;
    
    ${'' /* padding-top:85%; */}
    @media ${device.tablet}{
        text-align: center;
    }
    
`
export const LogoRow = styled(Row)`
    padding-right:30px;
    padding-top:8px;
    
    /* display:none; */
    .pr-2-2{
        padding-right: 2.3rem !important;
    }
    .zoom {
       
       transition: transform 1s;
       
       /* margin: 0 auto; */
   }

   .zoom:hover {
       -ms-transform: scale(1.2); /* IE 9 */
       -webkit-transform: scale(1.2); /* Safari 3-8 */
       transform: scale(1.2); 
   }
    ${'' /* @media ${device.laptopL}{
        padding-left:100px;
        
    }
    
    @media ${device.desktop}{
        padding-right:70px;  
    } */}
    @media ${device.tablet}{
        /* padding-right:80px;
        padding-bottom: 27px; */
        display:none;
    }
    
${'' /* @media (min-width: 1536px) and (max-width: 1536px){
        padding-left:180px;
        
}
@media (max-width: 2560px)
{
    padding-right: 0px;
}
@media (min-width: 1920px) and (max-width: 1920px){
        padding-left:250px;
        
} */}
    ${'' /* @media (max-width: 1366px) and (min-height: 578px){
    padding-right:45px;
    padding-top:5px;
}
@media (max-width: 1920px) and (min-height: 969px){
    padding-right:0px;
    padding-top:5px;
} */}
    
`
export const LogoRowMob = styled(Row)`
    display:none;
    .pr-2-2{
        padding-right: 2.3rem !important;
    }
    
    @media (max-width: 768px){
        display:flex;
        margin-bottom:20px;
      
    }

`