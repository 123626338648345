import React from 'react'
import { Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {WhyopsBanner, PageNo, PageSubText, GrowLead, GrowLeadContainer, Together, GrowLeadContent, PlantImg} from './Whyops-style';
import Plant from '../../images/Whyops/purple_plant 1.jpg';


function Whyops() {
    return (
        <>
           <WhyopsBanner fluid id="page-2">
               <Row>
                   <Col>
                        <PageNo>01</PageNo>
                        <PageSubText>Why ByteXP</PageSubText>
                        <GrowLeadContainer>
                            <Row>
                                <Col>
                                    <GrowLead>We Grow <Together>Together</Together></GrowLead>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <GrowLeadContent>
                                        We believe in founders and help them make 
                                        the world better using Tech that makes a 
                                        difference. Our journey from an idea to a product company is one where
                                        we work closely to support, communicate and innovate our way to 
                                        disrupt multiple verticals. 
                                    </GrowLeadContent>
                                </Col>
                            </Row>
                        </GrowLeadContainer>
                   </Col>
                   <Col md={6} xm={12} className="text-center pt-5">
                       <PlantImg src={Plant} alt="plant" className="img-fluid"/>
                   </Col>

               </Row>
            </WhyopsBanner> 
        </>
    )
}

export default Whyops
