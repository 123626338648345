import React, { useEffect, useState } from 'react'
import { Navbar,Form,Container,Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import Logo  from '../../images/Navbar/byteops_logo_desktop.svg'
import Logo  from '../../images/Navbar/byteops_logo_white.png'
// import LogoMob from '../../images/Navbar/logomob.png'
import LogoMob from '../../images/Navbar/byteops_logo_mobile.svg'
import Burger from '../../images/Navbar/burger-btn.svg'
import { BurgerImg, Menu, MenuImg, MenuLinks, MobLogo, NavBar, NavLogo, MenuBannerContainer,Animation } from './Navbar-styles';
import LogoWhite from '../../images/Navbar/byteops_logo_white.png'
import Cross from '../../images/Navbar/cross.png'
import MenuBanner from '../../images/Navbar/menu banner.png'
import {Link} from 'react-scroll';

function Landing() {
    
    const [menuOn, setMenu] = useState(false);
    const [scroll, setScroll] = useState(false);
    

   
    const toggleMenu = () => {
        
        // console.log(menuOn);
        document.getElementsByClassName('animated')[0].style.display = 'block';
        if(menuOn === false){
            
            setMenu(true);  
        }
        else{
            setTimeout(function(){
                document.getElementsByClassName('animated')[0].style.display = 'none';
            },1000);
            setMenu(false);
        }
    }

    // Function to Check Scroll for adding Box Shadow on Nav
    const scrollCheck = () => 
    {
        if(window.scrollY > 10){
            setScroll(true)
        }else
            setScroll(false)
    }
   
    // use Effect to Add Sroll Event Listener
    useEffect(() => {
        window.addEventListener('scroll',scrollCheck)
    }, [])
    
    return (
        <>
        <NavBar  expand="lg" fixed="top" className='bg-white'
            style={{
                transition: '0.5s',
                background: scroll ? '#ffffff' : 'transparent',
                boxShadow: scroll ? '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)' : 'none',
                padding:'5px'
            }}
        >
            <Navbar.Brand href="#home"><NavLogo src={Logo} alt="" className="img-fluid"/><MobLogo src={LogoMob} alt=''/></Navbar.Brand>
            
            
           
            <Form inline className="ml-auto mr-0">  
                <BurgerImg src={Burger} onClick={toggleMenu} alt=""/>
                
            </Form>
            
        </NavBar>
        
        
        <Animation animationIn="fadeIn" animationOut="fadeOut"  isVisible={menuOn} menu> 
        <Menu fluid >
            <NavBar  expand="lg" fixed="top" className='bg-dark'>
                <Navbar.Brand href="#home"><NavLogo src={LogoWhite} alt="" className="img-fluid"/><MobLogo src={LogoMob} alt=''/></Navbar.Brand>
               
                    
                    <Form inline className="ml-auto mr-0">
                        <BurgerImg src={Cross} onClick={toggleMenu} alt="" />
                
                    </Form>
                
            </NavBar>
        
            <MenuBannerContainer fluid >
                <Container>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-1" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>Home</MenuLinks>
                            </Link>
                        </Col>
                        
                    </Row>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-2" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>Why ByteXP</MenuLinks>
                            </Link>
                        </Col>
                        

                    </Row>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-3" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>How We Work</MenuLinks>
                            </Link>
                        </Col>
                        
                    </Row>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-4" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>Our Work</MenuLinks>
                            </Link>
                        </Col>
                        
                    </Row>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-5" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>Clients</MenuLinks>
                            </Link>
                        </Col>
                        
                    </Row>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-6" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>Case Studies</MenuLinks>
                            </Link>
                        </Col>
                        
                    </Row>
                    <Row className="justify-content-center pb-54">
                        <Col md={6} className="text-center">
                            <Link to="page-7" spy={true} smooth={true} onClick={toggleMenu}>
                               <MenuLinks>Contact Us</MenuLinks>
                            </Link>
                        </Col>
                    </Row>
                </Container>
                        
            </MenuBannerContainer>
            <MenuImg src={MenuBanner}  alt=""/>
           
        </Menu>
        </Animation>
    

        </>
    )
}

export default Landing
