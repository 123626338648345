import React from 'react'
import { Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ClientImage, OurClientBanner, OurClientBannerMob, OurClientPg,CstmCarousel, ClientMob } from './OurClient-style';
import { PageNo, PageSubText } from '../HowWeWork/HowWeWork-style';
import aynuk from '../../images/OurClient/Aynuk-purple.png'
import expl from '../../images/OurClient/Explorator-purple.png'
import greenCanvas from '../../images/OurClient/Green Canvas-purple.png'
import learningPlus from '../../images/OurClient/Learning Plus-purple.png'
import olika from '../../images/OurClient/Olika-purple 1.png'
import seedcode from '../../images/OurClient/Seedcode-purple.png'
import zak from '../../images/OurClient/Zak-purple.png'
import aynukOrg from '../../images/OurClient/Aynuk.png'
import explOrg from '../../images/OurClient/Explorator.png'
import greenCanvasOrg from '../../images/OurClient/Green Canvas.png'
import learningPlusOrg from '../../images/OurClient/Learning Plus.png'
import olikaOrg from '../../images/OurClient/Olika.png'
import SeedcodeOrg from '../../images/OurClient/Seedcode.png'
import zakOrg from '../../images/OurClient/Zak.png'
// import Carousel from 'react-bootstrap/Carousel'

function OurClient() {
    return (
        <>
            <OurClientPg fluid id="page-5">
                <Row>
                    <Col>
                        <PageNo >04</PageNo>
                        <PageSubText >Our Clients</PageSubText>
                    </Col>
                </Row>

            </OurClientPg>  
            <OurClientBanner fluid>
                <Row>
                    <Col className="text-center justify-content-center">
                        <div className="cardx">
                            <img  src={aynuk} className="img-fluid img-back" id="clientsAynuk" alt=""/>
                            <img src={aynukOrg} className="secondaryAynuk img-top zoom img-fluid" alt=""/>
                        </div>
                    </Col>
                    <Col className="text-center pt-4 align-self-center">
                        <div className="cardx">
                            <img  src={expl} alt="" className="img-fluid img-back" id="clientsExp"/>
                            <img  src={explOrg} alt="" className="secondaryExpl img-top img-fluid zoom"/>
                        </div>
                    </Col>
                    <Col className="text-center">
                        <div className="cardx">
                            <img  src={greenCanvas} alt="" className="img-fluid img-back" id="clientsGCP"/>
                            <img src={greenCanvasOrg} alt="" className="secondaryGcp img-fluid zoom img-top" />
                        </div>
                    </Col>
                </Row>
                <ClientImage className="height">
                    <Col className="text-center align-self-center ">
                        <div className="cardx">
                            <img  src={learningPlus} alt="" className="img-fluid img-back" id="clientsLP"/>
                            <img src={learningPlusOrg} alt="" className="secondaryLearningplus img-fluid img-top zoom"/>
                        </div>
                    </Col>
                    <Col className="text-center align-self-center ">
                        <div className="cardx">
                            <img  src={seedcode} alt="" className="img-fluid img-back" id="clientsSC"/>
                            <img src={SeedcodeOrg} alt="" className="secondaryLearningplus img-fluid img-top zoom"/>
                        </div>
                    </Col>
                    <Col className="text-center align-self-center">
                        <div className="cardx">
                            <img  src={zak} alt="" className="img-fluid img-back" id="clientsZak"/>
                            <img src={zakOrg} alt="" className="secondaryZak img-fluid img-top zoom"/>
                        </div>
                    </Col>
                </ClientImage>
                <ClientImage>
                    <Col className="text-center align-self-center">
                        <div className="cardx olikacardx">
                            <img  src={olika} alt="" className="img-fluid img-back" id="clientsolika"/>
                            <img src={olikaOrg} alt="" className="secondaryOlika img-fluid img-top zoom"/>
                        </div>
                    </Col>
                </ClientImage>
            </OurClientBanner>
            <OurClientBannerMob>
                
                    <CstmCarousel>
                        <CstmCarousel.Item>
                            <Row>
                                <ClientMob xs={12}>
                                    <img src={aynuk} alt="" className="img-fluid"/>
                                </ClientMob>
                              
                            </Row>
                        </CstmCarousel.Item>
                        <CstmCarousel.Item>
                            <Row>
                                
                                <ClientMob xs={12} className="align-self-center">
                                    <img src={expl} alt="" className="img-fluid" width="80%" height="auto"/>
                                </ClientMob>
                                
                            </Row>
                        </CstmCarousel.Item>
                        <CstmCarousel.Item>
                            <Row>
                                
                                <ClientMob xs={12}>
                                    <img src={greenCanvas} alt="" className="img-fluid"/>
                                </ClientMob>
                            </Row>
                        </CstmCarousel.Item>                        
                        {/* <CstmCarousel.Item>
                            <Row>
                                <ClientMob xs={12}>
                                    <img src={aynuk} alt="" className="img-fluid"/>
                                </ClientMob>
                                <ClientMob xs={12}>
                                    <img src={expl} alt="" className="img-fluid" width="80%" height="auto"/>
                                </ClientMob>
                                <ClientMob xs={12}>
                                    <img src={greenCanvas} alt="" className="img-fluid"/>
                                </ClientMob>
                            </Row>

                    
                        </CstmCarousel.Item> */}
                        <CstmCarousel.Item>
                            <Row>
                                <ClientMob xs={12}>
                                    <img src={learningPlus} alt="" className="img-fluid"/>
                                </ClientMob>
                              
                            </Row>

                
                        </CstmCarousel.Item>
                        <CstmCarousel.Item>
                            <Row>
                                
                                <ClientMob xs={12}>
                                    <img src={seedcode} alt="" className="img-fluid" width="80%" height="auto"/>
                                </ClientMob>
                               
                            </Row>

                
                        </CstmCarousel.Item>
                        <CstmCarousel.Item>
                            <Row>
                                
                                <ClientMob xs={12}>
                                    <img src={zak} alt="" className="img-fluid"/>
                                </ClientMob>
                            </Row>

                
                        </CstmCarousel.Item>
                    </CstmCarousel>        
                
            
            </OurClientBannerMob>
        </>
    )
}

export default OurClient
