import React from 'react'
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BannerLeadText, BannerSubText, LandingBanner, RightWay, BannerCol, ArrowDownContainer, BannerImage, DownArrowImg, LandingBannerMob } from './Landing-style';
import leadbanner from '../../images/Landing/leadbanner.jpg';
import DownArrowBtn from '../../images/Landing/Down.svg' ;
import LandingMob from '../../images/Landing/landingmob.png'
import {Link} from 'react-scroll';


// const StyledCol = styled(Col)`
//     background-color: orange;

// `

function Landing() {
   
    return (
        <>
        <LandingBanner id="page-1" fluid>
            <Row>
                <BannerCol  md={6}>
                    <BannerLeadText >Better Product Experience</BannerLeadText>
                    <BannerSubText >We Empathize and Ideate the <RightWay >RIGHT WAY</RightWay></BannerSubText>
                    
                </BannerCol>
                
                <BannerImage  src={leadbanner} alt='banner' className='img-fluid mr-0 ml-auto mt-0 banner-img'/>

                
                    
                
            </Row>
        </LandingBanner>
        <LandingBannerMob fluid>
            <Row>
                <Col xs={12}>
                    <img src={LandingMob} className="img-fluid" alt=''/>
                </Col>
                <Col xs={8}>
                    <BannerLeadText >Better Product Experience</BannerLeadText>
                    
                </Col>
                <Col xs={12}>
                    <BannerSubText >We Empathize and Ideate the <RightWay >RIGHT WAY</RightWay></BannerSubText>
                </Col>
            </Row>

        </LandingBannerMob>
        <ArrowDownContainer className="justify-content-center">
            <Row className="text-center">
                <Col>
                    <Link to="page-2" spy={true} smooth={true}><DownArrowImg src={DownArrowBtn} alt="downarrowbtn"/></Link>
                </Col>
            </Row>

        </ArrowDownContainer>
        

        </>
    )
}

export default Landing
