import styled from "styled-components"
import { Container,Col } from 'react-bootstrap';

const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };

  export const CaseStudiesPg = styled(Container)`
    padding-top: 15vh;
    
  
  `
  export const CaseStudiesBanner = styled(Container)`
  
    padding-top: 49px;
    padding-bottom: 90px;
    @media ${device.laptopL}{
        padding-top:30px;

    }
  `
//   export const CaseStudiesImageCol = styled(Col)`
//     direction:rtl;
//     padding-left: 24px;
//     overflow-y: auto;
//     overflow-x:hidden;
//     height:759px;
//     margin-left:150px;
    
//     ::-webkit-scrollbar {
//         width: 17px;
//         margin-left: 50px;

//     }

// /* Track */
//     ::-webkit-scrollbar-track {
//         /* box-shadow: inset 0 0 5px grey;  */
//         background:#E5E5E5;
//         border-radius: 10px;    
//     }
 
// /* Handle */
//     ::-webkit-scrollbar-thumb {
//     background: #7A57D1; 
//     border-radius: 10px;
//     }

// /* Handle on hover */
//     ::-webkit-scrollbar-thumb:hover {
//     background: #7A57D1; 
//     }
//     @media ${device.laptopL}{
//         margin-left: 85px;
//         padding-left: 0px;
//         height: 629px;
//         ::-webkit-scrollbar {
//         width: 10px;
//         margin-left: 50px;

//     }
//     }
//     @media ${device.laptop}{
//       height:569px;

//     }
//     @media ${device.tablet}{
//      display:none;
      
//     }
  
//   `
  export const CaseStudiesImageMob = styled(Col)`
  /* display:none; */
      padding-top:30px;
      .horizontal-scroll {
        display:block;
        overflow: hidden;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 30px 0; */
        ::-webkit-scrollbar {
        height: 10px;
        margin-left: 50px;

    }

/* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;    
    }
 
/* Handle */
    ::-webkit-scrollbar-thumb {
    background: #7A57D1; 
    border-radius: 10px;
    }

/* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #7A57D1; 
    }
      }
      .item {
  
        display: inline-block;
        width: 150px;
        margin: 0 10px;

      }
    
    @media (min-width: 768px){
        margin-left:19px;
        margin-right:19px;
    }
    @media (min-width: 1024px){
      margin-left:75px;
      margin-right: 75px;
    }
    @media (min-width: 1441px){
      margin-left:150px;
      margin-right:150px;
    }
      
  `
  export const CaseStudiesImage = styled.img`
    padding-bottom: 51px;
    direction:ltr;
    cursor:pointer;
    @media ${device.tablet}{
      display:inline-block;
    }
    
  `
  export const CaseStudiesLead = styled.h1`
    /* font-family: 'DM Sans', sans-serif; */
    font-style: normal;
    font-weight: 800;
    font-size: 29px;
    line-height: 37px;
    padding-left: 10px;
    color: #7A57D1;
    margin-bottom:0;
   
  `

  export const CaseStudiesContent = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 20px;
    text-align: justify;
    padding-top: 30px;
    color: #2F353E;
    margin-bottom:0;
    padding-left: 10px;
    padding-right: 10px;
    @media ${device.laptopL}{
        padding-top: 15px;
        font-size: 16px;
        line-height: 103%;
    }
  `
  
  export const BannerImg = styled(Col)`
    .banner-img{
      max-width:100%;
      height:auto;
    }
    .btn-primary{
      /* font-family: 'Montserrat', sans-serif; */
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;

      color: #FFFFFF;
      width: 168px;
      height: 50px;
      /* right:-30; */
      /* bottom:0; */
        position: absolute;
  top: 86%;
  left: 71%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      /* margin-top: -25%;
      margin-left:70%; */
      border:none;
      background: #7A57D1;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
      border-radius: 54px; 
      /* position: absolute; */

    }
    @media ${device.laptopL}{
      .btn-primary{
        left:88%;
        width:148px;
        height:40px;
        font-size:16px;
      }
    }
    @media ${device.tablet}{
     
     .btn-primary{
      left: 87%;
      width: 115px;
      height:35px;
      font-size: 12px;
     } 

    }
    @media ${device.mobileL}{
      .btn-primary{
        left:82%;
        width: 79px;
        top:84%;
      }
    }
  `
  export const CaseStudiesContentBanner = styled(Col)`
    padding-left:150px;
    padding-right:150px;
    @media ${device.laptopL}{
      padding-left:75px;
      padding-right:75px;
    }
    @media ${device.tablet}{
      padding-left:19px;
      padding-right:19px;
    }
  `