import styled from "styled-components"
import { Container} from 'react-bootstrap';


const size = {
    mobileS : '320px',
    mobileM : '375px',
    mobileL: '425px',
    tablet : '768px',
    laptop : '1024px',
    laptopL : '1440px',
    desktop : '2560px'
}
const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`
  };

export const WhyopsBanner = styled(Container)`
    padding-top:15vh;
    padding-bottom:90px;
    @media ${device.laptopL}{
        padding-top:14vh;
    }
`
export const PageNo = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 300;
    font-size: 55px;
    line-height: 121.3%;
    /* or 73px */
    padding-left:80px;
    margin-bottom:0;
    color: rgba(122, 87, 209, 0.5);
    @media ${device.laptopL}{
        padding-left:74px;
        font-size: 40px;
    }
    @media ${device.tablet}{
        padding-left:19px;
    }

`
export const PageSubText = styled.p`
    /* font-family: 'Montserrat', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 121.3%;
    padding-left: 83px;
    
/* or 36px */


    color: #2F353E;
    @media ${device.laptopL}{
        padding-left: 74px;
        font-size: 20px;
    }
    @media ${device.tablet}{
        padding-left:19px;
    }
`
export const GrowLead = styled.h1`
    /* font-family: 'Nunito', sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 71px;
    padding-left:11px;
    color: #2F353E;
    @media ${device.laptopL}{
        padding-left:64px;
        font-size: 38px;
    }
    @media ${device.tablet}{
        padding-left:0;
        font-size: 40px;
        line-height: 49px;
    }
    @media (min-width: 1920px)  {
        padding-left:51px;

    }
    @media (max-width: 3000px) and (min-width: 1449px) {
        padding-left:51px;

    }

`
export const PlantImg = styled.img`
   
    @media ${device.laptopL}{
        width:40%;
        height: auto;
    }
    @media ${device.laptop}{
        width: 55%;
        height:auto;
    }
    @media (min-width: 1536px) and (max-width: 1536px)  {
        width: 48%;
        height:auto;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        width: 48%;
        height:auto;

    }
    
`
export const GrowLeadContainer = styled(Container)`
    padding-top: 210px;
    @media ${device.laptopL}{
        padding-top: 100px;
    }
    @media ${device.tablet}{
        padding-top: 27px;
    }
    
`
export const Together = styled.span`
    color:#7A57D1;
    font-weight: 800;

`
export const GrowLeadContent = styled.p`
    /* font-family: 'Mulish', sans-serif; */
    font-style: normal;
    font-weight:600;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 0;
    padding-left:11px;
    color: #2F353E;
    @media ${device.laptopL}{
        padding-left:64px;
        font-size: 16px;
        line-height: 20px;
    }
    @media (min-width: 1920px) and (max-width: 1920px)  {
        padding-left:51px;

    }
    @media (max-width: 3000px) and (min-width: 1449px) {
        padding-left:51px;

    }
    @media ${device.tablet}{
        font-weight: 500;
        font-size: 16px;
        line-height: 103.4%;
        padding-top:37px;
/* or 17px */
        padding-left:0;

        color: #2F353E;
    }
`