import React, {useState} from 'react'
import { Button,Container,Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import { CaseStudiesBanner, CaseStudiesImage, CaseStudiesImageCol, CaseStudiesPg,CaseStudiesLead, CaseStudiesContent, Scrollable, CaseStudiesImageMob, BannerImg, CaseStudiesContentBanner } from './CaseStudies-style';
import { PageNo, PageSubText } from '../HowWeWork/HowWeWork-style';
import Mask0mini from '../../images/CaseStudies/Mask Group.jpg'
import Mask1mini from '../../images/CaseStudies/Mask Group (1).jpg'
import Mask2mini from '../../images/CaseStudies/Mask Group (2).jpg'
import Mask3mini from '../../images/CaseStudies/Mask Group (3).jpg'

import Mask0 from '../../images/CaseStudies/Mask Group Main.jpg'





function CaseStudies() {
    const Cases = [
        {
            id: 'whatsapp_overhaul',
            lead: 'Whatsapp Overhaul',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Netus blandit purus, orci egestas maecenas ultrices convallis tristique. Id non non et orci, pharetra mauris arcu tellus sit. Leo pellentesque.',
            src:'/static/media/Mask Group Main.39301377.jpg'

        },
        {
            id: 'mask_1',
            lead: 'Whatsssup',
            content: 'Lorem ipsum',
            src: '/static/media/Mask Group (1).5510eff1.jpg'
        },
        {
            id: 'mask_2',
            lead: 'notWhatsapp',
            content: 'Lorem Ispum',
            src: '/static/media/Mask Group (2).f16ac11a.jpg'
        }
    ]
    const [cases, setCase] = useState(0);
    const changeCase = (prop) => {
        
        let str = prop.target.id;
        str = str.slice(str.length - 1, str.length);
        // console.log(str);
        setCase(str);

        
    }
    return (
        <>
            <CaseStudiesPg fluid id="page-6">
                <Row>
                    <Col>
                        <PageNo>05</PageNo>
                        <PageSubText>Case Studies</PageSubText>
                    </Col>
                </Row>
            </CaseStudiesPg>
            <CaseStudiesBanner fluid>
                <Row>
                    
                    {/* <CaseStudiesImageMob>
                        <section className="horizontal-scroll">
                        <div className="item"><CaseStudiesImage src={Mask0mini} id="Mask0" onClick={changeCase} className="img-fluid" alt=""/></div>
                            <div className="item"><CaseStudiesImage src={Mask1mini} id="Mask1" onClick={changeCase} className="img-fluid" alt=""/></div>
                            <div className="item"><CaseStudiesImage src={Mask2mini} id="Mask2" onClick={changeCase} className="img-fluid" alt=""/></div>
                            <div className="item"><CaseStudiesImage src={Mask3mini} id="Mask3" onClick={changeCase} className="img-fluid" alt=""/></div>
                            
                        </section>
                    </CaseStudiesImageMob> */}
                    <CaseStudiesContentBanner xs={{span:12, order:'first'}} >
                        <Row>
                            <Col>
                                <CaseStudiesLead >{Cases[cases].lead}</CaseStudiesLead>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                            {/* <img src={Mask0} alt=""/> */}
                                <CaseStudiesContent >
                                    {Cases[cases].content}
                                </CaseStudiesContent>
                                
                                
                            </Col>
                        </Row>
                        <Row>
                            <BannerImg>
                                <img src={Cases[cases].src} className="banner-img pt-3" alt=""/>
                                <button type="button" className="btn btn-primary font-mont">Read More</button>
                            </BannerImg>
                        </Row>
                        
                    </CaseStudiesContentBanner>
                </Row>
            </CaseStudiesBanner>  
        </>
    )
}

export default CaseStudies
